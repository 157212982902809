// DownloadAppScreen.js
import React, { useEffect } from 'react';
import './DownloadAppScreen.scss'; // Assuming your CSS styles are here
import { GrAppleAppStore } from 'react-icons/gr';
import { BsAndroid, BsAndroid2 } from 'react-icons/bs';
import { ReactComponent as IOSLogo } from './ios.svg'
import { isMobile, isTablet } from 'react-device-detect';

const DownloadAppScreen = ({ platform }) => {


    useEffect(() => {
        const pathName = window.location.origin;
        if (window.location.href?.includes('/feed/') && (isMobile || isTablet)) {
            window.location.href = `flaplive://feed/${window.location.href?.replace(`${window.location.origin}/feed/`, '')}`;
        }
        if (window.location.href?.includes('/shoot/') && (isMobile || isTablet)) {
            window.location.href = `flaplive://shoot/${window.location.href?.replace(`${window.location.origin}/shoot/`, '')}`;
        }
        if (window.location.href?.includes('/watch/') && (isMobile || isTablet)) {
            window.location.href = `flaplive://news/${window.location.href?.replace(`${window.location.origin}/watch/`, '')}`;
        }
    }, []);


    return (
        <div className="download-app">
            <img style={{ cursor: 'pointer', width: 110 }} src='/assets/images/logo.png' className='logo mb-4' />
            {
                platform === 'IOS' && (
                    <div style={{
                        backgroundColor: '#f6f6f6',
                        padding: '15px 20px',
                        borderRadius: 18,

                    }}>
                        <div style={{
                            backgroundColor: '#ededed',
                            borderRadius: '50%',
                            width: 'fit-content',
                            width: 50,
                            height: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='mb-2'>
                            <GrAppleAppStore size={20} />
                        </div>
                        <p style={{ color: '#444', fontSize: 14 }} className='mb-2'>Mobile Phone / Tablet</p>

                        <p style={{ color: '#000', fontSize: 20, fontWeight: 700 }} className='mb-2'>iOS</p>
                        <p style={{ color: '#444', fontSize: 14 }} className='mb-2'>
                            Stay ahead with quick, adaptable communication strategies for evolving business landscapes.</p>
                        <div className='mt-3'>
                            <a href='https://apps.apple.com/us/app/flaplive/id6448904291'>
                                <IOSLogo />
                            </a>
                        </div>
                    </div>
                )
            }
            {
                platform === 'ANDROID' && (
                    <div style={{
                        backgroundColor: '#f6f6f6',
                        padding: '15px 20px',
                        borderRadius: 18,

                    }} className='mt-3'>
                        <div style={{
                            backgroundColor: '#ededed',
                            borderRadius: '50%',
                            width: 'fit-content',
                            width: 50,
                            height: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='mb-2'>
                            <BsAndroid2 size={20} />
                        </div>
                        <p style={{ color: '#444', fontSize: 14 }} className='mb-2'>Mobile Phone / Tablet</p>

                        <p style={{ color: '#000', fontSize: 20, fontWeight: 700 }} className='mb-2'>Android</p>
                        <p style={{ color: '#444', fontSize: 14 }} className='mb-2'>
                            Stay ahead with quick, adaptable communication strategies for evolving business landscapes.</p>
                        <div className='mt-3'>
                            <a href='https://play.google.com/store/apps/details?id=com.flaplive&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play'
                                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                style={{
                                    maxWidth: 150,
                                    marginLeft: -10
                                }} /></a>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default DownloadAppScreen;
